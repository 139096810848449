<template>
  <v-card flat class="mt-md-3 mx-md-8 mx-lg-16">
    <v-card-title>
      <span class="headline">Zuschuss Listen Generator</span>
      <v-spacer />
    </v-card-title>
    <v-card-subtitle>
      Erstelle csv Dateien mit den benötigten Teilnehmer Informationen.
    </v-card-subtitle>
    <v-divider />
    <v-card-text class="pb-0">
      <div>Filtere nach Alter</div>
      <div>Von {{ ageFilter[0] }} bis {{ ageFilter[1] }} Jahren</div>
      <v-range-slider
        class="mt-8"
        v-model="ageFilter"
        thumb-label="always"
        thumb-size="24"
        ticks="always"
      ></v-range-slider>
      <v-row>
        <v-col class="col-12 col-md-6 col-lg-4 py-0">
          <v-text-field
            class="mt-0 pt-0"
            v-model="search"
            append-icon="mdi-magnify"
            label="Suche"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <div class="mt-6">
        Gefilterte Mitglieder: (Anzahl: {{ filteredUsers.length }})
      </div>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="filteredUsers"
      :search="search"
      show-select
      item-key="id"
      selectable-key="id"
      v-model="selected"
    >
      <template v-slot:item.birthday="{ item }">
        {{ formatBirthday(item.birthday) }}
      </template>
      <template v-slot:item.roles="{ item }">
        <div v-if="!item.activities">
          Keine Aktivitäten
        </div>
        <div v-else>
          <v-chip
            class="mr-1 my-1"
            v-for="activity in filterActiveActivities(item.activities)"
            :key="activity.nami_id"
            :color="nameToColor(activity.name)"
            >{{ activity.name }}</v-chip
          >
        </div>
      </template>
    </v-data-table>
    <v-card-text>
      <div v-if="selected.length > 0">
        Ausgewählte Mitglieder (Anzahl: {{ selected.length }}):
      </div>
      <div v-for="user in selected" :key="user.id">
        <v-divider />
        <v-checkbox
          class="mt-0 pb-1"
          hide-details
          dense
          v-model="selected"
          :value="user"
          color="grey"
        >
          <template v-slot:label>
            <div>
              {{
                user.forename +
                  " " +
                  user.surname +
                  "  " +
                  formatBirthday(new Date(user.birthday)) +
                  "  " +
                  user.street +
                  " " +
                  user.postalCode +
                  " " +
                  user.city
              }}
            </div>
          </template>
        </v-checkbox>
      </div>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-btn
        class="ma-2"
        @click="csvExportDialog = true"
        :disabled="selected.length === 0"
      >
        <v-icon class="mr-1">
          print
        </v-icon>
        Liste Erstellen
      </v-btn>
    </v-card-actions>
    <csv-export-dialog
      :data="selected"
      :headers="csvExportHeaders"
      v-model="csvExportDialog"
    />
  </v-card>
</template>

<script>
import Parser from "../utils/Parser";
import { mapActions } from "vuex";
import CsvExportDialog from "@/components/utility/CsvExportDialog";

export default {
  name: "GrantGenerator",
  components: { CsvExportDialog },
  data() {
    return {
      ageFilter: [6, 27],
      selected: [],
      search: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Geburtsdatum", value: "birthday", sortable: false },
        { text: "Anschrift", value: "address", sortable: false },
        { text: "Rollen", value: "roles", sortable: false }
      ],
      csvExportDialog: false,
      csvExportHeaders: [
        {
          title: "Geschlecht",
          propName: "gender",
          modifier: gender => {
            return gender === "männlich" ? "m" : "w";
          }
        },
        { title: "Vorname", propName: "forename" },
        { title: "Nachname", propName: "surname" },
        { title: "Straße", propName: "street" },
        { title: "PLZ", propName: "postalCode" },
        { title: "Ort", propName: "city" },
        { title: "Land", propName: "country" },
        {
          title: "Geburtstag",
          propName: "birthday",
          modifier: birthday => {
            return Parser.parseDate(new Date(birthday));
          }
        }
      ]
    };
  },
  computed: {
    allUsersRaw() {
      return this.$store.state.users.users;
    },
    allUsers() {
      let users = this.allUsersRaw;
      this.addName(users);
      this.addAddress(users);
      return users;
    },
    filteredUsers() {
      return this.allUsers
        .filter(user => {
          let diff = new Date().getTime() - new Date(user.birthday).getTime();
          return (
            diff >= this.yearToMilliseconds(this.ageFilter[0]) &&
            diff <= this.yearToMilliseconds(this.ageFilter[1])
          );
        })
        .sort((a, b) => {
          return (
            new Date(a.birthday).getTime() - new Date(b.birthday).getTime()
          );
        });
    }
  },
  methods: {
    ...mapActions("users", ["refreshUsers"]),
    filterUsers(activityName) {
      return this.allUsers.filter(entity => {
        return (
          entity.activities &&
          entity.activities.find(
            activity => activity.active && activity.name === activityName
          )
        );
      });
    },
    addName(users) {
      users.forEach(entity => {
        entity.name = entity.forename + " " + entity.surname;
      });
    },
    addAddress(users) {
      users.forEach(entity => {
        entity.address =
          entity.street + " " + entity.postalCode + " " + entity.city;
      });
    },
    formatBirthday(birthday) {
      return Parser.parseDate(new Date(birthday));
    },
    yearToMilliseconds(year) {
      return year * 365.4 * 24 * 60 * 60 * 1000;
    },
    filterActiveActivities(activities) {
      return activities.filter(activity => {
        return activity.active;
      });
    },
    nameToColor(name) {
      return Parser.nameToColor(name);
    }
  },
  mounted() {
    this.refreshUsers();
  }
};
</script>

<style scoped></style>
