<template>
  <v-card flat class="mt-md-3 mx-md-8 mx-lg-16">
    <v-card-title>
      <span class="headline">Bankeinzug Listen Generator</span>
      <v-spacer />
    </v-card-title>
    <v-card-subtitle>
      Erstelle csv Dateien mit den benötigten Informationen für den Einzug der
      Mitgliedsbeiträge.
    </v-card-subtitle>
    <v-divider />
    <v-card-text class="pb-0">
      <v-row>
        <v-text-field
          style="max-width: 300px"
          class="mt-0 pt-0 mb-3"
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-row>
    </v-card-text>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="contribution_collection_data"
      :search="search"
      item-key="identifier"
    >
    </v-data-table>
    <v-divider />
    <v-card-actions>
      <v-btn class="ma-2" @click="csvExportDialog = true">
        <v-icon class="mr-1">
          print
        </v-icon>
        Liste Erstellen
      </v-btn>
    </v-card-actions>
    <csv-export-dialog
      :data="contribution_collection_data"
      :headers="csvExportHeaders"
      v-model="csvExportDialog"
    />
  </v-card>
</template>

<script>
import CsvExportDialog from "@/components/utility/CsvExportDialog";
import { mapActions, mapState } from "vuex";
import UsersAPI from "@/api/UsersAPI";
import Parser from "@/utils/Parser";

export default {
  name: "ContributionCollection",
  components: { CsvExportDialog },
  data() {
    return {
      loading: false,
      contribution_collection_data: [],
      iban_payee: "DE75547900000014229132",
      creditor_id: "DE09ZZZ00001456650",
      amount_normal: "46,50",
      amount_reduced: "41,00",
      search: "",
      headers: [
        { text: "Vorlagenbezeichnung", value: "identifier" },
        { text: "IBAN des Zahlungsempfängers", value: "iban_payee" },
        { text: "IBAN des Zahlungspflichtigen", value: "iban_payer" },
        { text: "BIC", value: "bic" },
        { text: "Kreditinstitut", value: "credit_institute" },
        { text: "Zahlungspflichtiger", value: "payer" },
        { text: "Adresse", value: "address" },
        { text: "Land", value: "land" },
        { text: "Länderkennzeichen", value: "land_id" },
        { text: "Betrag", value: "amount" },
        { text: "Verwendungszweck", value: "purpose" },
        { text: "LastschriftArt", value: "debit_type" },
        { text: "Mandatsart", value: "mandate_type" },
        { text: "Mandatsreferenz", value: "mandate_reference" },
        { text: "Gläubiger-ID", value: "creditor_id" },
        { text: "unterschrieben am", value: "signature_date" },
        { text: "Abweichender Zahlungsempfänger", value: "differing_payee" }
      ],
      csvExportDialog: false,
      csvExportHeaders: [
        { title: "Vorlagenbezeichnung", propName: "identifier" },
        { title: "IBAN des Zahlungsempfängers", propName: "iban_payee" },
        { title: "IBAN des Zahlungspflichtigen", propName: "iban_payer" },
        { title: "BIC", propName: "bic" },
        { title: "Kreditinstitut", propName: "credit_institute" },
        { title: "Zahlungspflichtiger", propName: "payer" },
        { title: "Adresse", propName: "address" },
        { title: "Land", propName: "land" },
        { title: "Länderkennzeichen", propName: "land_id" },
        { title: "Betrag", propName: "amount" },
        { title: "Verwendungszweck", propName: "purpose" },
        { title: "LastschriftArt", propName: "debit_type" },
        { title: "Mandatsart", propName: "mandate_type" },
        { title: "Mandatsreferenz", propName: "mandate_reference" },
        { title: "Gläubiger-ID", propName: "creditor_id" },
        { title: "unterschrieben am", propName: "signature_date" },
        { title: "Abweichender Zahlungsempfänger", propName: "differing_payee" }
      ]
    };
  },
  computed: {
    ...mapState("users", ["users"])
  },
  methods: {
    ...mapActions("users", ["refreshUsers"]),
    async createContributionCollectionData() {
      this.loading = true;
      let currentYear = new Date().getFullYear();
      this.contribution_collection_data = this.users.filter(entity => {
        let address =
          entity.street + " " + entity.postalCode + " " + entity.city;
        return !address.includes("not defined");
      });
      this.contribution_collection_data = await Promise.all(
        this.contribution_collection_data.map(async entity => {
          let financialData = await this.getFinancialData(entity.id);
          console.log(financialData);
          return {
            identifier: entity.forename + "_" + entity.surname,
            iban_payee: this.iban_payee,
            iban_payer: financialData.IBAN,
            bic: financialData.BIC,
            credit_institute: financialData.Bank,
            payer: financialData.AccountHolder,
            address:
              entity.street + " " + entity.postalCode + " " + entity.city,
            land: "",
            land_id: "",
            amount: this.membershipRateToAmount(financialData.MembershipRate),
            purpose: `Beitrag Pfadfinder ${currentYear} ${entity.forename}`,
            debit_type: "Basis-Lastschrift",
            mandate_type: "",
            mandate_reference: `${entity.namiMembershipNumber}`,
            creditor_id: this.creditor_id,
            signature_date: Parser.parseDate(new Date(entity.namiEntryDate)),
            differing_payee: ""
          };
        })
      );
      this.loading = false;
    },
    async getFinancialData(userId) {
      return UsersAPI.getFinancialData(userId)
        .then(financialData => {
          return financialData;
        })
        .catch(error => {
          console.log(error);
          return {};
        });
    },
    membershipRateToAmount(memberShipRate) {
      switch (memberShipRate) {
        case "Voller Beitrag":
          return this.amount_normal;
        case "Voller Beitrag - Stiftungseuro":
          return this.amount_normal;
        case "Familienermäßigt":
          return this.amount_reduced;
        default:
          return "ALERT";
      }
    }
  },
  mounted() {
    this.refreshUsers();
    this.createContributionCollectionData();
  }
};
</script>

<style scoped></style>
